import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import Carousel from 'nuka-carousel';
import useWindowSize from '../../hooks/use-window-size';
import Image from '../ImageQuerys/UsCarousel';
import chevron from '../../images/svg/chevron-right-blue.svg';
import { viewports, colors } from '../../style-vars';
import ArrowLeft from '../CarouselCustomControls/ArrowLeft';
import ArrowRight from '../CarouselCustomControls/ArrowRight';

const { smOrSmaller, mdOrSmaller } = viewports;
const { black200, primary } = colors;

const { Title } = Typography;

const UsCarousel = (props) => {
  const { sectionData } = props;
  const [numberOfSlides, setNumberOfSlides] = useState(3);
  const [cellSpacing, setCellSpacing] = useState(10);
  const size = useWindowSize();

  useEffect(() => {
    const { width } = size;
    const isMobileOnly = width <= 576;
    const isTablet = width > 576 && width < 992;
    const isSreenLG = width > 992 && width < 1200;
    const screenXL = width > 1200;

    const slides = () => {
      if (isMobileOnly) return 1.1;
      if (isTablet) return 1.5;
      if (isSreenLG) return 3;
      if (screenXL) return 3;
      return 3;
    };
    const spacing = () => {
      if (isMobileOnly) return 10;
      if (isTablet) return 30;
      return 30;
    };

    setNumberOfSlides(slides);
    setCellSpacing(spacing);
  }, [size]);
  return (
    <section className="us-carousel">
      <Title level={2} className="text-center">
        {sectionData.title}
        <span className="blue_dot">.</span>{' '}
      </Title>
      <p className="text-center">{sectionData.subtext}</p>
      <div className="us-carousel__car lateral-shadows">
        <div className="us-carousel__circle" />
        <Carousel
          slidesToShow={numberOfSlides}
          cellSpacing={cellSpacing}
          enableKeyboardControls
          renderBottomCenterControls={null}
          transitionMode="scroll3d"
          renderCenterLeftControls={({ previousSlide }) => (
            <ArrowLeft onClick={previousSlide} />
          )}
          renderCenterRightControls={({ nextSlide }) => (
            <ArrowRight onClick={nextSlide} />
          )}
          dragging
          swiping
          wrapAround
          className="slider-adapted"
        >
          {sectionData.images.map((image, idx) => (
            <Image
              imageName={image}
              key={`us-carousel__${idx.toString()}`}
              className="us-carousel__img"
            />
          ))}
        </Carousel>
      </div>

      <style jsx global>{`
        .us-carousel {
          position: relative;
          padding: 40px 160px;
        }
        @media ${mdOrSmaller} {
          .us-carousel {
            padding: 40px 100px;
          }
        }
        @media ${smOrSmaller} {
          .us-carousel {
            padding: 40px 32px;
          }
        }
        .us-carousel h2,
        .us-carousel p {
          padding: 0 15px;
        }
        .us-carousel h2 {
          font-weight: 800 !important;
          margin-bottom: 24px !important;
        }
        .us-carousel p {
          font-size: 16px;
          color: ${black200};
        }
        .us-carousel__car {
          margin-top: 51px;
          position: relative;
        }
        .us-carousel__circle {
          position: absolute;
          width: 300px;
          height: 300px;
          left: 50%;
          background-color: ${primary};
          border-radius: 350px;
          transform: translate(-50%, -32px);
        }
        @media ${mdOrSmaller} {
          .us-carousel__circle {
            display: none;
          }
        }
        .us-carousel__img {
          border-radius: 8px;
          max-height: 190px;
        }
        .us-carousel .btn-right {
          width: 64px;
          height: 64px;
          transform: translateX(-80px) translateY(-50px);
          position: absolute;
        }
        .us-carousel .btn-right p {
          width: 0;
          height: 0;
          opacity: 0;
        }
        @media ${mdOrSmaller} {
          .us-carousel .btn-right {
            display: none;
          }
        }
      `}</style>
    </section>
  );
};

UsCarousel.propTypes = {
  sectionData: PropTypes.objectOf(PropTypes.any),
};
UsCarousel.defaultProps = {
  sectionData: '',
};

export default UsCarousel;
